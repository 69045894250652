import api from "../services/api/axiosService";
import { focusAtom } from 'jotai-optics'
import { atom, getDefaultStore } from "jotai";
import { OpticFor_ } from "optics-ts";
import { produce } from "immer";
import { IdAndNameAndSelectDto, IdAndNameDto } from "src/types";
import { CourseEditResponse, TinyTheme } from "./domain/CourseEditModel";
import { selectedAtom } from "src/utils/SelectedAtom";

import coursePartService from "src/coursepart/CoursePartService";
import lastDataDB, { OpenedEditData } from "src/services/db/LastOpenedDB";
import { waitRippleservice } from "src/services/WaitRippleService";
import { CourseCopyCommand } from "./domain/CourseCopyCommand";
import { CoursePrintDTO } from "./domain/Print";
import { urlService } from "src/services/NavService";
import { CourseAddOnDTO, CourseAddonFormDTO } from "./domain/CourseAddonDTO";
import { currentUserAtom } from "src/services/user/userService";


const store = getDefaultStore();

export const openCoursesAtom = atom<Record<string, CourseEditResponse>>({});
openCoursesAtom.debugLabel = "openCoursesAtom";

export const currentCourseAtom = atom<string | undefined>(undefined);
currentCourseAtom.debugLabel = "currentCourseAtom";

export const lastCoursesAtom = atom<IdAndNameDto[]>([]);
lastCoursesAtom.debugLabel = "lastCoursesAtom";

export const oneCourseatom = (id: string) => {

    const atom = focusAtom(
        openCoursesAtom,
        (optic: OpticFor_<Record<string, CourseEditResponse>>) => optic.prop(id)
    )
    atom.debugLabel = "course: " + id;
    return atom;
};

export const oneThemeAtom = (courseId: string, themeId: string) => {

    const atom = focusAtom(
        openCoursesAtom,
        (optic: OpticFor_<Record<string, CourseEditResponse>>) => {
            return optic.prop(courseId).lens(data => {
                return data.Course.Themes.find(t => t.Id === themeId);
            }, (course, theme) => {
                return course;
            })

        }
    )
    atom.debugLabel = "theme: " + themeId;
    return atom;
};


export const courseListAtom = atom((get) => {

    const courses = get(openCoursesAtom);
    const selected = get(selectedAtom);

    const courseList: IdAndNameAndSelectDto[] = Object.keys(courses)
        .map(id => courses[id])
        .filter(d => !!d).map(data => {
            return {
                Id: data!.Course.Id,
                Name: data!.Course.Name,
                Selected: selected.course === data!.Course.Id || false,
                Dirty: data!.IsDirty,
                ConnectedWithCourse: false
            }
        });

    return courseList;
});

courseListAtom.debugLabel = "courseListAtom";


const endPoints = {
    OPEN_COURSE: (courseId: string, forceUnlock?: boolean) => `/author/course/${courseId}${forceUnlock ? "?forceUnlock=true" : ""}`,
    CREATE_COURSE: `/author/course`,
    COPY_COURSE: (courseId: string) => `/author/course/${courseId}/copy`,
    CLOSE_COURSE: (courseId: string) => `/author/course/${courseId}/endEdit`,
    DELETE_COURSE: (courseId: string) => `/author/course/${courseId}`,
    SAVE_COURSE: (courseId: string) => `/author/course/${courseId}/save`,
    PUBLISH_COURSE: (courseId: string) => `/author/course/${courseId}/publish`,
    PRINT_COURSE: (courseId: string) => `/author/course/${courseId}/print`,
    ADD_THEME: (courseId: string) => `/author/course/${courseId}/addTheme`,
    SAVE_ADDON: (courseId: string) => `/author/course/${courseId}/addon`,

}

export class CourseService {

    private navigator: (url: string) => void = (url: string) => { };

    public userLoggedIn(userId: string) {
        lastDataDB.courses.filter(d => d.userid === userId).toArray()
            .then(allData => {
                allData.sort((a, b) => b.dato.getTime() - a.dato.getTime());
                store.set(lastCoursesAtom, allData.map(d => ({ Id: d.itemid, Name: d.name })));
            });
    }

    public clearCurrentCourseId() {
        store.set(currentCourseAtom, undefined);
    }

    public setNavigator(action: (url: string) => void) {
        this.navigator = action;
    }

    public getTheme(courseId: string, themeId: string) {
        return store.get(oneThemeAtom(courseId, themeId));
    }

    public updateThemeName(courseId: string, themeId: string, text: string) {
        this.MutateCourseState(courseId, courseData => {
            const theme = courseData.Course.Themes.find(t => t.Id === themeId);
            if (theme) {
                theme.Name = text;
                courseData.IsDirty = true;
            }
        });

        this.saveCourse(courseId);
    }

    public areCoursesDirty() {
        const courseData = store.get(openCoursesAtom);
        return Object.keys(courseData).find(key => courseData[key] && courseData[key].IsDirty);
    }

    public arePartsInCourseDirty(courseId: string) {
        const data = store.get(oneCourseatom(courseId));
        if (data) {
            return data.Course.Themes.find(theme => theme.Parts.find(p => p.Dirty)) !== undefined;
        }
        else {
            return false;
        }

    }

    public saveCourseDescription(courseId: string, description: string | undefined) {

        this.MutateCourseState(courseId, draft => {
            draft.Course.Description = description || "";
        });

        this.saveCourse(courseId);
    }

    public saveCourseName(courseId: string, name: string) {
        this.MutateCourseState(courseId, draft => {
            draft.Course.Name = name || "";
        });

        this.saveCourse(courseId);
    }

    public async publish(courseId: string) {

        try {
            waitRippleservice.setState(true);

            const response = await api.post<CourseEditResponse>(endPoints.PUBLISH_COURSE(courseId), null);
            if (response && response.status === 200) {

                const oldData = store.get(openCoursesAtom);
                const newData = produce(oldData, draft => {
                    draft[courseId] = response.data;
                });

                store.set(openCoursesAtom, newData);
            }
        } finally {
            waitRippleservice.setState(false);
        }
    }

    public async createCourse(name: string) {
        const response = await api.post<string>(endPoints.CREATE_COURSE, { Value: name });
        if (response && response.status === 200) {
            return response.data;
        }
        return null;
    }

    public async deleteCourse(id: string) {
        const response = await api.delete<void>(endPoints.DELETE_COURSE(id));
        if (response && response.status === 200) {

            const oldData = store.get(openCoursesAtom);
            const newData = produce(oldData, draft => {
                delete draft[id];
            })
            store.set(openCoursesAtom, newData);

            this.removeFromLastCourses(id);

            const ids = Object.keys(newData);
            if (ids.length > 0) return ids[0];
        }
        return null;
    }

    public async copyCourse(cmd: CourseCopyCommand) {
        const response = await api.post<string>(endPoints.COPY_COURSE(cmd.CourseId), cmd);
        if (response && response.status === 200 && response.data) {
            return response.data;
        }
    }

    public async loadCourse(courseid: string, forceUnlock?: boolean) {

        coursePartService.fetchAuthors();
        const currentUser = store.get(currentUserAtom);
        const userId = currentUser!.Id;


        const oldData = store.get(openCoursesAtom);

        if (!forceUnlock && oldData[courseid]) {
            store.set(selectedAtom, { course: courseid });
            store.set(currentCourseAtom, courseid);
            this.addToLastCourses({ userid: userId, id: courseid, name: oldData[courseid].Course.Name })
            return oldData[courseid];
        }

        try {
            waitRippleservice.setState(true);
            const response = await api.get<CourseEditResponse>(endPoints.OPEN_COURSE(courseid, forceUnlock));
            if (response && response.status === 200) {
                const course = { ...response.data, LastUrl: `/course/${courseid}` }

                course.Course.Themes.map((t, i) => t.Open = (i === 0 || t.Parts.length === 0));
                const newData = produce(oldData, draft => {
                    draft[courseid] = course;

                });

                store.set(openCoursesAtom, newData);
                store.set(selectedAtom, { course: courseid });
                store.set(currentCourseAtom, courseid);

                this.addToLastCourses({ userid: userId, id: courseid, name: course.Course.Name });

                return course;
            }

            return null;
        }
        finally {
            waitRippleservice.setState(false);
        }
    }

    public async saveCourse(courseId: string) {
        const data = store.get(oneCourseatom(courseId));

        const course = { ...data.Course, AddOns: data.Course.AddOns.filter(a => a.Selected) };

        await api.post(endPoints.SAVE_COURSE(courseId), course);
        this.MutateCourseState(courseId, draft => draft.IsDirty = true);
    }

    public async closeAllCourses(force?: boolean) {
        if (!force && this.areCoursesDirty()) {
            return false;
        }

        store.set(openCoursesAtom, {});

    }

    public async closeCourse(courseId: string) {
        try {
            await api.post(endPoints.CLOSE_COURSE(courseId), null);

            const oldData = store.get(openCoursesAtom);
            const toBeDeleted = oldData[courseId];
            const partIds = toBeDeleted.Course.Themes.map(t => t.Parts.map(p => p.Id)).flat();

            coursePartService.closeParts(partIds);

            const newData = produce(oldData, draft => {
                delete draft[courseId];
            });
            store.set(openCoursesAtom, newData);
            store.set(currentCourseAtom, undefined);

            const list = store.get(courseListAtom);
            if (list.length > 0) {
                return list[0].Id;
            }
            return null;
        }
        catch (e: any) {
            return false;
        }
    }

    public setDataUrl(courseId: string, url: string) {
        this.MutateCourseState(courseId, (model => {
            model.LastUrl = url;
        }));
    }

    public getUrl(id: string) {
        const atom = oneCourseatom(id);
        if (atom) {
            let courseData = store.get(atom);
            return courseData.LastUrl || urlService.urlToCourse(id);
        }
    }

    public getCourse(id: string) {
        const atom = oneCourseatom(id);
        if (atom) {
            return store.get(atom);
        }

        return undefined;
    }

    public async fetchPrintData(courseId: string, types: number[], edit: boolean) {
        const response = await api.post<CoursePrintDTO>(endPoints.PRINT_COURSE(courseId), types);
        if (response && response.data) {
            this.MutateCourseState(courseId, (state) => {
                state.PrintData = response.data;
            });
        }

    }

    public async clearPrintData(courseId: string) {
        this.MutateCourseState(courseId, (state) => {
            state.PrintData = undefined;
        });
    }

    public toggleThemeOpen(courseId: string, themeId: string) {
        this.MutateCourseState(courseId, state => {
            const theme = state.Course.Themes.find(t => t.Id === themeId);
            if (theme) theme.Open = !!!theme.Open;
        })
    }

    public openThemeWithThisPart( partId: string ){
        const currentCourseId = store.get(currentCourseAtom);
        if( currentCourseId){
            this.MutateCourseState(currentCourseId, state => {
                const theme = state.Course.Themes.find(t => t.Parts.find(p => p.Id === partId));
                if (theme) theme.Open = true;
            })
        }
    }


    public async addTheme(courseId: string, name: string) {

        try {
            waitRippleservice.setState(true);
            const response = await api.post<TinyTheme>(endPoints.ADD_THEME(courseId), { Value: name })
            if (response.status === 200) {
                this.MutateCourseState(courseId, draft => {
                    draft.Course.Themes.push(response.data);
                });
            }
        } finally {
            waitRippleservice.setState(false);
        }

    }

    public addPart(courseId: string, themeId: string, newPart: IdAndNameAndSelectDto) {
        this.MutateCourseState(courseId, draft => {
            const theme = draft.Course.Themes.find(t => t.Id === themeId);
            if (theme) {
                theme.Parts.push(newPart);
            }
        });

        this.saveCourse(courseId);
    }

    public async addNewPart(name: string, courseId: string, themeId: string) {
        const part = await coursePartService.createPart(name);
        if (part) {
            this.MutateCourseState(courseId, draft => {
                const theme = draft.Course.Themes.find(t => t.Id === themeId);
                if (theme) {
                    theme.Parts = [...theme.Parts, { ...part, Dirty: false }]
                }
            });

            this.saveCourse(courseId);
        }
    }

    public async removePart(courseId: string, themeId: string, partId: string) {

        let nextId: string | undefined;
        this.MutateCourseState(courseId, draft => {
            const theme = draft.Course.Themes.find(t => t.Id === themeId);
            if (theme) {
                theme.Parts = theme.Parts.filter(p => p.Id !== partId);
                if (theme.Parts.length > 0) {
                    nextId = theme.Parts[0].Id;
                }

            }
        });
        await this.saveCourse(courseId);
        return nextId;

    }

    public async deleteTheme(courseId: string, themeId: string) {
        this.navigator(`/course/${courseId}/general`);

        this.MutateCourseState(courseId, draft => {
            draft.Course.Themes = draft.Course.Themes.filter(t => t.Id !== themeId);
        })
        await this.saveCourse(courseId);

        return true;

    }

    public partNameUpdated(partId: string, name: string) {
        const courseId = store.get(currentCourseAtom);

        this.MutateCourseState(courseId!, draft => {
            let part: IdAndNameAndSelectDto | undefined;
            if (draft) {
                draft.Course.Themes.forEach(t => {
                    var pa = t.Parts.find(p => p.Id === partId);
                    if (pa) part = pa
                })

                if (part) part.Name = name;
            }
        });
    }

    public partDeleted(partId: string) {
        const courses = store.get(openCoursesAtom);
        const keys = Object.keys(courses);

        let courseId: string | undefined;

        if (keys.length === 0) return;

        const newData = produce(courses, data => {
            for (let id = 0; id < keys.length; id++) {
                let theme: TinyTheme | undefined;
                const key = keys[id];
                if (data[key]) {
                    data[key].Course.Themes.forEach(t => {
                        var pa = t.Parts.find(p => p.Id === partId);
                        if (pa) theme = t;
                    });
                }

                if (theme) {
                    theme.Parts = theme.Parts.filter(p => p.Id !== partId);
                    courseId = key;
                    break;
                }
            }
        });

        store.set(openCoursesAtom, newData);

        return courseId;
    }


    public MutateCourseState(courseId: string, method: (state: CourseEditResponse) => void) {

        const atom = oneCourseatom(courseId);
        let state = store.get(atom);

        const newstate = produce(state, (draft) => {
            method(draft);
        })

        store.set(atom, newstate);
    }

    public setPartDirty(partId: string, dirty: boolean) {
        const courses = store.get(openCoursesAtom);
        if (courses) {
            let changed = false;
            const newData = produce(courses, draft => {
                Object.keys(draft).forEach(key => {
                    if (draft[key]) {
                        draft[key].Course.Themes.forEach(t => {
                            const part = t.Parts.find(p => p.Id == partId);
                            if (part) {
                                part.Dirty = dirty;
                                changed = true;
                            }
                        })
                    }
                });

            });

            if (changed) {
                store.set(openCoursesAtom, newData);
            }
        }
    }

    public setAddon(courseId: string, addonId: number, checked: boolean) {

        let saveMe = false;
        this.MutateCourseState(courseId, state => {
            const addon = state.Course.AddOns.find(a => a.Id === addonId);
            if (addon) {
                addon.Selected = checked;
                saveMe = true;
            }
        });

        if (saveMe) {
            this.saveCourse(courseId);
        }
    }

    public async saveAddon(courseId: string, data: CourseAddOnDTO) {

        const response = await api.post<CourseAddonFormDTO>(endPoints.SAVE_ADDON(courseId), data);
        if (response && response.status === 200) {
            const newData = response.data;
            this.MutateCourseState(courseId, draft => {
                const addon = draft.Course.AddOns.find(a => a.Id === newData.Id);
                if (!addon) {
                    draft.Course.AddOns.push(newData);
                }
                else {
                    addon.Description = newData.Description;
                    addon.Header = newData.Header;
                    addon.Link = newData.Link;
                }
            });
        }

    }

    private async addToLastCourses(data: { userid: string, id: string, name: string }) {

        let existingRow = await lastDataDB.courses.get({ itemid: data.id, userid: data.userid });
        if (existingRow) {
            existingRow.dato = new Date();
            await lastDataDB.courses.update(existingRow.id!, existingRow);
        }
        else {

            const newData: OpenedEditData = {
                itemid: data.id,
                dato: new Date(),
                name: data.name,
                userid: data.userid
            }

            let allrows = await lastDataDB.courses.filter(d => d.userid === data.userid).toArray();

            if (allrows.length + 1 > 10) {
                // get the oldest
                allrows.sort((a, b) => a.dato.getTime() - b.dato.getTime());
                const todelete = allrows[0];
                await lastDataDB.courses.delete(todelete.id!);
            }

            await lastDataDB.courses.add(newData);
        }

        const allData = await lastDataDB.courses.filter(d => d.userid === data.userid).toArray();

        // sort Descending by date
        allData.sort((a, b) => b.dato.getTime() - a.dato.getTime());
        store.set(lastCoursesAtom, allData.map(d => ({ Id: d.itemid, Name: d.name })));

    }

    private async removeFromLastCourses(courseId: string) {

        const currentUser = store.get(currentUserAtom);
        let existingRow = await lastDataDB.courses.get({ itemid: courseId, userid: currentUser!.Id });
        if (existingRow) {
            await lastDataDB.courses.delete(existingRow.id!);
        }
        const allData = await lastDataDB.courses.filter(d => d.userid === currentUser!.Id).toArray();

        // sort Descending by date
        allData.sort((a, b) => b.dato.getTime() - a.dato.getTime());
        store.set(lastCoursesAtom, allData.map(d => ({ Id: d.itemid, Name: d.name })));

    }


}

const courseService = new CourseService();
export default courseService;