import { atom, getDefaultStore } from "jotai";
import api from "src/services/api/axiosService";
import { EditLectureDto, EditScreenDto, LectureFileDto, LectureUpdatTextsCommand } from "./domain/LectureState";
import { produce } from "immer";
import { FileType, ScreenFileUploadedRequest } from "./domain/ScreenFileUploadedRequest";
import { CompleteUpload } from "src/components/FileUpload/FileUploader";
import { focusAtom } from "jotai-optics";
import { OpticFor_ } from "optics-ts";
import screenEncodingService from "./Screen/ScreenEncodingService";
import { CheckEncodingResponse } from "./domain/CheckEncodingResponse";
import localAudioService from "./LocalAudioService";
import coursePartService from "../CoursePartService";
import fileUploadservice from "src/components/FileUpload/FileUploadService";
import { currentUserReadOnlyAtom } from "src/services/user/userService";
import lecturePlayerService from "./Player/LecturePlayerService";
import { CopyScreenCommand } from "./domain/CopyScreenCommand";
import { API_URL } from "src/utils/constants";
import { ScreenItemStatus } from "../domain/ScreenItemStatus";

const store = getDefaultStore();


export const LECTUREFILE_UPLOADED = (lectureId: string) => `/author/coursepart/lecture/${lectureId}/FileUploaded`;

const endPoints = {

    REORDER_SCREENS: (lectureId: string) => `/author/coursepart/lecture/${lectureId}/SetScreensOrder`,
    DELETE_SCREEN: (lectureId: string, screenId: string) => `/author/coursepart/lecture/${lectureId}/${screenId}/DeleteScreen`,
    GET_LECTURE: (lectureId: string, includeDeleted: boolean) => `/author/coursepart/lecture/${lectureId}/${includeDeleted}/GetLectureData`,
    GET_LECTURE_DIFF: (lectureId: string ) => `/author/lecture/${lectureId}/diff`,
    GET_LECTURE_FILES: (lectureId: string) => `/author/coursepart/lecture/${lectureId}/file`,
    UPDATE_LECTURE_TEXTS: (lectureId: string) => `/author/coursepart/lecture/${lectureId}/updateTexts`,
    COPY_SCREEN: (lectureId: string) => `/author/coursepart/lecture/${lectureId}/copyscreen`,
    GET_ZIPPED_LECTURE: (lectureId: string, published: boolean) => `/author/lecture/${lectureId}/${published}/GetZipLecture`,

}

export const lecturesDiffAtom = atom<boolean>(false);
lecturesDiffAtom.debugLabel = "lecturesDiffAtom";


export const lecturesAtom = atom<Record<string, EditLectureDto>>({});
lecturesAtom.debugLabel = "lectures";

export const oneLectureatom = (lectureId: string) => {
    const atom = focusAtom(
        lecturesAtom,
        (optic: OpticFor_<Record<string, EditLectureDto>>) => optic.prop(lectureId)
    )
    atom.debugLabel = "lecture: " + lectureId;
    return atom;
};

export class LectureService {


    public mutateLecturesState(lectId: string, method: (state: EditLectureDto) => void) {

        const atom = oneLectureatom(lectId);

        let state = store.get(atom);
        const newstate = produce(state, (draft) => {
            method(draft);
        });
        store.set(atom, newstate);

        coursePartService.MutatePartState(state.CoursePartId, (draft) => {
            draft.Data!.Dirty = true;
        });


    }

    public async copyScreen(cmd: CopyScreenCommand) {

        const response = await api.post<EditScreenDto>(endPoints.COPY_SCREEN(cmd.destLecture), cmd);
        if (response && response.status === 200) {

            this.mutateLecturesState(cmd.destLecture, (lecture) => {
                lecture.Screens = [...lecture.Screens, response.data];
                lecture.Screens.forEach((s, i: number) => s.Order = i)
            });
        }
    }

    public async deleteScreen(lectureId: string, screenId: string) {
        const response = await api.delete<void>(endPoints.DELETE_SCREEN(lectureId, screenId));

        if (response && response.status == 200) {

            this.mutateLecturesState(lectureId, (lectData) => {
                lectData.Screens = lectData.Screens.filter(s => s.ScreenId !== screenId);
                lectData.Screens.forEach((l, i) => l.Order = i);

                screenEncodingService.removeScreen(screenId, lectData.CoursePartId);

            });
            

            let lectureDataAtom = oneLectureatom(lectureId);
            const lectureData = store.get(lectureDataAtom);
            this.setLectureImage(lectureData);
            coursePartService.decrementNumOfScreens(lectureData.CoursePartId, lectureId);

        }
    }

    public setDiffMode(state: boolean) {
        store.set(lecturesDiffAtom, state);
    }


    public ClearLectureData(lectureId: string) {

        const allLecturesData = store.get(lecturesAtom);
        const newData = produce(allLecturesData, data => {
            delete data[lectureId];
        });

        store.set(lecturesAtom, newData);
    }

    public async saveScreensOrder(lectureId: string) {

        let lectureDataAtom = oneLectureatom(lectureId);
        const lectureData = store.get(lectureDataAtom);
        if (lectureData) {
            const screenIds = lectureData.Screens.map(l => l.ScreenId)
            await api.post<void>(endPoints.REORDER_SCREENS(lectureId), screenIds);
            this.setLectureImage(lectureData);
        }
    }



    public addScreen(userId: string, lecture: EditLectureDto, addPromise: Promise<CompleteUpload>) {
        addPromise
            .then((file) => {

                const type = this.getType(file);

                const data: ScreenFileUploadedRequest = {
                    UserID: userId,
                    LectureID: lecture.LectureId,
                    FileKey: file.Key!,
                    FileName: file.name,
                    FileType: type,
                    ScreenID: null,
                    InsertAfterThisScreen: null
                }

                api.post<EditScreenDto>(LECTUREFILE_UPLOADED(lecture.LectureId), data)
                    .then(async response => {
                        if (response && response.data) {

                            this.mutateLecturesState(lecture.LectureId, (lectData) => {
                                response.data.Order = lectData.Screens.length;
                                lectData.Screens = [...lectData.Screens, response.data];
                            });

                            screenEncodingService.addScreens([response.data.ScreenId], lecture.CoursePartId);
                            this.setLectureImage(lecture);
                            coursePartService.incrementNumOfScreens(lecture.CoursePartId, lecture.LectureId);
                        }
                    });

            }).catch((e) => {

            });
    }

    public async getLectureData(lectureId: string, getDeleted: boolean) {

        let endpoint = endPoints.GET_LECTURE(lectureId, false);
        if( getDeleted){
            endpoint = endPoints.GET_LECTURE_DIFF(lectureId);
        }


        const response = await api.get<EditLectureDto>(endpoint);

        if (response && response.status == 200 && response.data) {

            const lectureData = store.get(lecturesAtom);
            const lectDataFromServer = response.data;

            lectDataFromServer.Screens.forEach((s, i) => s.Order = i);

            // make sure first image is the lecture image
            this.setLectureImage(lectDataFromServer);


            const newData = produce(lectureData, data => {
                data[lectureId] = lectDataFromServer;
            });

            store.set(lecturesAtom, newData);

            lecturePlayerService.clearLecture();

            const screensInConversion = response.data.Screens.filter(sc => sc.RunningConversion).map(sc => sc.ScreenId);
            screenEncodingService.addScreens(screensInConversion, lectDataFromServer.CoursePartId );


            return response.data;
        }
        return {};



    }

    public async UpdateScreen(screen: EditScreenDto) {

        this.mutateLecturesState(screen.LectureId, (lectData) => {
            lectData.Screens =
                lectData.Screens.map(s => s.ScreenId === screen.ScreenId ? screen : s);
        });
    }

    public UpdateScreensFromEncodingResults(response: CheckEncodingResponse) {


        let lectureData = store.get(lecturesAtom);

        const newData = produce(lectureData, lectures => {

            if (response.Reloaded === true) {
                this.getLectureData(response.LectureId, false);
                response.Screens.forEach(s => {
                    if (s.GotSound && !s.RunningConversion) {
                        localAudioService.ClearAudioData(s.ScreenId);
                    }
                });
            }
            else {
                const lecture = lectures[response.LectureId];
                const firstScreenId = lecture.Screens[0].ScreenId;

                lecture.Screens =
                    lecture.Screens.map(sc => {
                        const newScreenData = response.Screens.find(s => s.ScreenId == sc.ScreenId);
                        if (newScreenData) {
                            localAudioService.ClearAudioData(newScreenData.ScreenId);


                        }
                        return newScreenData || sc;
                    });

            }
        });

        store.set(lecturesAtom, newData);
        lectureData = store.get(lecturesAtom);
        this.setLectureImage(lectureData[response.LectureId]);

    }

    public async screenFileUpdate(message: React.ReactNode, screen: EditScreenDto, file: File) {

        const user = store.get(currentUserReadOnlyAtom)
        fileUploadservice.startUploadState();


        const response = fileUploadservice.uploadFile(message, user!.Id, file);
        const result = await response.promise;
        if (result.Key) {
            const type = this.getType(result);

            const data: ScreenFileUploadedRequest = {
                UserID: user!.Id,
                LectureID: screen.LectureId,
                FileKey: result.Key!,
                FileName: file.name,
                FileType: type,
                ScreenID: screen.ScreenId,
                InsertAfterThisScreen: null
            }

            api.post<EditScreenDto>(LECTUREFILE_UPLOADED(screen.LectureId), data)
                .then(async response => {
                    if (response && response.data) {
                        const dto = response.data;

                        this.mutateLecturesState(screen.LectureId, (lecture) => {

                            lecture.Screens = lecture.Screens.map((sc, i) => {

                                if (sc.ScreenId === dto.ScreenId) {
                                    dto.Order = i + 1;
                                    return dto;
                                }
                                return sc;
                            });

                        });

                        
                        lecturePlayerService.clearLecture();

                        let lectureDataAtom = oneLectureatom(screen.LectureId);
                        const lectureData = store.get(lectureDataAtom);
                        this.setLectureImage(lectureData);
                        
                        screenEncodingService.addScreens([response.data.ScreenId],lectureData.CoursePartId);
                    }
                });
        }
    }

    public async updateTexts(cmd: LectureUpdatTextsCommand) {

        const response =
            await api.post<void>(endPoints.UPDATE_LECTURE_TEXTS(cmd.LectureId), cmd);

        if (response && response.status == 200) {

            this.mutateLecturesState(cmd.LectureId, (lect) => {
                lect.Description = cmd.Description;
                lect.Name = cmd.Name;
            });
        }
    }

    /* Original media */
    public getZippedLecture(lectureId: string, published: boolean) {
        const url = new URL(`${API_URL}/author/${lectureId}/${published}/GetZipLecture`);
        window.open(url.toString());
    }

    public getVideoOriginal(lectureId: string, screenId: string) {
        window.open(`${API_URL}/author/${lectureId}/${screenId}/GetVideoOriginalMedia`);
    }


    private getType(data: CompleteUpload): FileType {

        if (data.type.indexOf("application/") > -1 
            || data.type.indexOf("powerpoint") > -1 
            || data.type.indexOf("presentation") > -1) {

                return FileType.Presentation;
                
        }

        if (data.type.indexOf("video") > -1) {
            return FileType.Video;
        }

        if (data.type.indexOf("audio") > -1) {
            return FileType.Sound;
        }

        if (data.type.indexOf("image") > -1) {
            return FileType.Image;
        }

        throw new Error( `Mysterius filetype: ${data.type}`);

    }

    private setLectureImage(lectureData: EditLectureDto) {

        const imageScreen = lectureData.Screens.find(s => s.Status !== ScreenItemStatus.Deleted && s.ImageUrl);
        const imageUrl = imageScreen?.ImageUrl || "/imgs/icons/newlect.gif"
        coursePartService.updateLectureImage(lectureData.CoursePartId, lectureData.LectureId, imageUrl);

    }

}

const lectureService = new LectureService();

export default lectureService;